import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { ThawPortfolio } from '_templates'
import { AppContext } from '_context'
import variables from '_config/css-variables'

const ThawCaseStudy = ({ data }) => {
  const content = data.contentfulThawCaseStudy
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  useEffect(() => {
    setNavbarColor(variables['thaw-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <ThawPortfolio
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageBackgroundImage={content.pageBackgroundImage.file.url}
      heroImage={content.heroImage.fluid}
      heroDescription={content.heroDescription.internal.content}
      productTitle={content.productTitle}
      productDescription={content.productDescription.internal.content}
      productImage={content.productImage.fluid}
      heroBackgroundImage={content.heroBackgroundImage}
      heroBackgroundImageMobile={content.heroBackgroundImageMobile.fluid}
      challengeTitle={content.challengeTitle}
      challengeDescription={content.challengeDescription.internal.content}
      challengeDescription2={content.challengeDescription2.internal.content}
      challengeImage={content.challengeImage.fluid}
      featuresDescription={content.featuresDescription.internal.content}
      featuresImage={content.featuresImage.fluid}
      featuresBackgroundImage={content.featuresBackgroundImage.fluid}
      personalizedSettingsTitle={content.personalizedSettingsTitle}
      personalizedSettingsImage={content.personalizedSettingsImage.fluid}
      personalizedSettingsDescription={content.personalizedSettingsDescription.internal.content}
      personalizedSettingsBackgroudImage={content.personalizedSettingsBackgroudImage}
      digitalExperienceTitle={content.digitalExperienceTitle}
      digitalExperienceDescription={content.digitalExperienceDescription.internal.content}
      digitalExperienceBackgroundImage={content.digitalExperienceBackgroundImage}
      digitalExperienceImage={content.digitalExperienceImage.fluid}
      shareTitle={content.shareTitle}
      shareDescription={content.shareDescription.internal.content}
      shareImage={content.shareImage.fluid}
      shareBackgroundImage={content.shareBackgroundImage.fluid}
      testimonials={content.testimonials}
      resultsTitle1={content.resultsTitle1}
      resultsDescription1={content.resultsDescription1}
      resultsDescription2={content.resultsDescription2}
      resultsDescription3={content.resultsDescription3}
      proudCases={content.proudCases}
      navbarColorOverride={variables['thaw-primary']}
    />
  )
}

export const query = graphql`
  query contentfulThawCaseStudy($locale: String) {
    contentfulThawCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageBackgroundImage {
        description
        file {
          url
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      productTitle
      productDescription {
        internal {
          content
        }
      }
      productDescription {
        internal {
          content
        }
      }
      productImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroBackgroundImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroBackgroundImageMobile {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      challengeDescription2 {
        internal {
          content
        }
      }
      challengeImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featuresDescription {
        internal {
          content
        }
      }
      featuresImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featuresBackgroundImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      personalizedSettingsTitle
      personalizedSettingsDescription {
        internal {
          content
        }
      }
      personalizedSettingsImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      personalizedSettingsBackgroudImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      digitalExperienceTitle
      digitalExperienceDescription {
        internal {
          content
        }
      }
      digitalExperienceBackgroundImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      digitalExperienceImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shareTitle
      shareDescription {
        internal {
          content
        }
      }
      shareImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shareBackgroundImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      testimonials {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      resultsTitle1
      resultsDescription1 {
        internal {
          content
        }
      }
      resultsDescription2 {
        internal {
          content
        }
      }
      resultsDescription3 {
        internal {
          content
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

ThawCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default ThawCaseStudy
